<template>
  <div>
    <c-tab
      ref="patrolTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :tabItems="tabItems"
      :height="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam.sync="popupParam"
          :patrol.sync="patrol"
          :attachInfo.sync="attachInfo"
          :imprTabInfo.sync="imprTabInfo"
          :isOld="isOld"
          :disabled="disabled"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
export default {
  name: 'patrolDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      patrol: {
        saiPatrolId: '',
        plantCd: '',
        processCd: '',
        vendorCd: '',
        sopMapId: '',
        patrolTypeCd: '',
        patrolDate: '',
        patrolName: '',
        patrolResultCd: null,
        patrolResultSummary: '',
        patrolCompleteFlag: 'N',
        sopConstructionId: '',
        ramRiskAssessmentPlanId: '',
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        regUserId: '',
        chgUserId: '',
        results: [],
        deleteResults: [],
        imprs: [],
      },
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'PATROL_RESULT',
        taskKey: '',
      },
      tab: 'info',
      editable: true,
      detailUrl: '',
      resultItemUrl: '',
    };
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 70) + 'px';
    },
    tabItems() {
      let items = [{ name: 'info', icon: 'edit', label: '순회점검 정보', component: () => import(`${'./patrolInfo.vue'}`), key: uid() }];
      if (this.isOld) {
        items.push({ name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/mobileImprTab.vue'}`) })
      }
      return items;
    },
    isOld() {
      return Boolean(this.popupParam.saiPatrolId)
    },
    disabled() {
      return this.patrol.patrolCompleteFlag === "Y"
        // 결재중인 경우 비활성화
        || (this.patrol.approvalStatusCd === 'ASC0000001' && !this.popupParam.isApprContent)
    },
    imprTabInfo() {
      return {
        // Mobile용 Start
        // customCol에 대한 card title
        requestContentsCols: ['patrolItemName'],
        // readOnly용으로 card-detail 내용이 들어감
        customCols: [
          {
            key: uid(), // v-for key
            label: '비고', // grid에 대한 하나의 column label
            name: 'patrolContents' // grid에 대한 하나의 column name
          }
        ],
        // Mobile용 End
        key: this.popupParam.saiPatrolId, // 
        ibmTaskTypeCd: 'ITT0000065',
        ibmTaskUnderTypeCd: 'ITTU000100',
        requestContents: this.patrol.checkName,
        disabled: this.disabled,
        isOld: this.isOld,
        gridItem: {
          listUrl: '',
          param: null,
          title: '점검항목별 개선 목록', // 점검항목별 개선 목록
          merge: [],
          data: this.$_.filter(this.patrol.results, (result) => {
            return result.ibmTooltip
          }),
          columns: [
            {
              name: 'patrolItemName',
              field: 'patrolItemName',
              label: '점검항목',
              align: 'left',
              sortable: false,
            },
            {
              name: 'patrolResultName',
              field: 'patrolResultName',
              label: '점검결과',
              align: 'center',
              style: 'width:90px',
              sortable: false,
            },
          ],
          research: {},
        },
        gridOther: {
          title: '항목외 개선 목록', // 항목외 개선 목록
        },
      }
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.patrol.get.url;
      this.resultItemUrl = selectConfig.sai.patrol.resultItem.list.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.saiPatrolId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.patrol, _result.data);
          this.$set(this.attachInfo, 'taskKey', this.popupParam.saiPatrolId)
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        this.patrol.patrolDate = this.$comm.getToday();
        this.patrol.patrolName = this.patrol.patrolDate + ' 순회점검'
        this.$http.url = this.resultItemUrl
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.$store.getters.user.plantCd,
          useFlag: 'Y',
        };
        this.$http.request((_result) => {
          this.$_.forEach(_result.data, _item => {
            this.patrol.results.push({
              saiPatrolId: '',
              saiPatrolResultId: uid(),
              patrolItemName: _item.patrolItemName,
              patrolResultCd: 'PRCC000001',
              patrolContents: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: 'C',
            }) 
          })
        },);
      }
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
